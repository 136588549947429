import "../../App.css";
import { CoverTextLeft } from "../index";
import { contctAllData } from "../../Data/contactData";

const ContactWall = () => {
  return (
    <div className="contact-header">
      <div className="container">
        <CoverTextLeft
          tit={contctAllData.pageTitle}
          subTit={contctAllData.coverTxt}
        />
      </div>
    </div>
  );
};

export default ContactWall;
