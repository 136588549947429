import "../../App.css";

import { ProjectCard, IntroSummary } from "../../Components/index";

import {
  projectsDetails,
  projectSectionDetails,
  mainProjectDetails,
} from "../../Data/projectsData";

const ProjectSec = () => {
  return (
    <div className="container">
      <div className="d-flex flex-wrap margin-sec">
        <div className="text-center ">
          <IntroSummary
            title={projectSectionDetails.mainTitle}
            para={projectSectionDetails.paragraph}
          />
        </div>
        <div className="d-flex flex-wrap justify-content-center ">
          <div
            className="d-flex flex-wrap justify-content-center  "
            style={{ backgroundColor: "#101010", margin: "auto" }}
          >
            <iframe
              width="100%"
              src="https://www.youtube.com/embed/3vVwtu6rfjU"
              title="Online-Store App Promo"
              frameborder="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div
            style={{
              marginTop: "0.1em",
              marginBottom: "0.5em",
              textAlign: "center",
            }}
          >
            <h4 className="proj-h4">{mainProjectDetails.title}</h4>
            <h6 className="proj-h6">{mainProjectDetails.sub_title}</h6>
            <p
              style={{
                textAlign: "center",
              }}
              className="proj-p"
            >
              {mainProjectDetails.detail}
            </p>
          </div>
        </div>
        <div
          class=" d-flex flex-row flex-wrap justify-content-center proj-sec "
          // style={{ margin: "auto" }}
        >
          {projectsDetails.map((project) => {
            return (
              <div
                key={project.id}
                className="d-flex justify-content-center proj-card"
                style={{
                  marginRight: "0.5em",
                  marginLeft: "0.5em",
                }}
              >
                <ProjectCard
                  image={project.image}
                  title={project.title}
                  subTitle={project.sub_title}
                  detailes={project.detail}
                  link={project.link}
                  seemore=""
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProjectSec;
