import React from "react";
import { ContactWall, Map } from "../../Components/index";
import { Contact, ContactSec, ContactSm } from "../../sections/index";

const ContactPage = () => {
  return (
    <>
      <ContactWall />
      <ContactSec />
      <Map />
      <div className="contact-footer-lg">
        <Contact />
      </div>
      <div className="contact-footer-sm">
        <ContactSm />
      </div>{" "}
    </>
  );
};

export default ContactPage;
