import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../../App.css";
import { contctAllData } from "../../Data/contactData";
import { ToastContainer } from "react-toastify";
import notify from "../useNotify";

const ContactShortForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ieh4wdi",
        "template_n9a1fkp",
        form.current,
        "R2UYDjTcfJ10GWcyg"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    notify("Your Email Sent Successfully", "success");

    e.target.reset();
  };

  return (
    <>
      <h4>Newsletter</h4>
      <p>{contctAllData.newsletter}</p>
      <div class="short-form-sm input-group  mb-3">
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="email"
            name="user_email"
            className=" form-control form-control-sm"
            placeholder="Type Your Email"
          />
          <textarea
            name="message"
            className="form-control form-control-sm"
            placeholder="Type The Message"
            style={{
              fontSize: "0.75em",
              borderRadius: "0.75em",
              display: "none",
            }}
            value="From Newsletter Website Form"
          />
          <input className="btn email" type="submit" value="Send" />
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default ContactShortForm;
