import "../../App.css";
import {
  ContactWrapper,
  ContactShortForm,
  ContactCard,
  UsefulLinks,
} from "../../Components/index";

const Contact = () => {
  return (
    <ContactWrapper>
      <div className="d-flex flex-row justify-content-center ">
        <div className="" style={{ width: "35%" }}>
          <ContactCard />
        </div>

        <div
          className="justify-content-end"
          style={{
            width: "30%",
            textAlign: "center",
            paddingTop: "0.5em",
          }}
        >
          <UsefulLinks />
        </div>

        <div className="" style={{ width: "35%", paddingTop: "0.5em" }}>
          {" "}
          <ContactShortForm />
        </div>
      </div>
    </ContactWrapper>
  );
};

export default Contact;
