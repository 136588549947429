import "../../App.css";

const Intro = (props) => {
  return (
    <div className=" text-center intro-sec">
      <div className="container">
        <div className="d-flex flex-row flex-wrap justify-content-around">
          <div className=" col col-lg-9 col-md-9 col-sm-11 col-xs-11 col-11 ">
            <h2>
              <span>{props.me}</span>
              {props.name}
            </h2>
            <p>{props.parahraph}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const IntroSummary = ({ title, para }) => {
  return (
    <div className="container intro-summary-sec">
      <div className="d-flex flex-row justify-content-around">
        <div className="col col-lg-11 col-md-11 col-sm-11 col-xs-11 col-12 ">
          <h2>{title}</h2>
          <p>{para}</p>
        </div>
      </div>
    </div>
  );
};

const LangDetailes = ({ title, para }) => {
  return (
    <>
      <div className="col col-lg-6 col-md-11 col-sm-11 col-xs-11 col-11 lang-det">
        <h2 style={{ paddingBottom: "0.2em" }}>{title}</h2>
        <p>{para}</p>
      </div>
    </>
  );
};

export default Intro;
export { IntroSummary };
export { LangDetailes };
