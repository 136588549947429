import "../../App.css";
import { SectionWrapper, AboutCard } from "../../Components/index";

const About = () => {
  return (
    <div className="container margin-sec ">
      <SectionWrapper>
        <AboutCard />
      </SectionWrapper>
    </div>
  );
};

export default About;
