import "../../App.css";
import {
  ContactWrapper,
  ContactShortForm,
  ContactCard,
  UsefulLinks,
} from "../../Components/index";

const ContactSm = () => {
  return (
    <ContactWrapper>
      <div className="d-flex flex-row flex-wrap justify-content-center ">
        <div>
          <ContactCard />
        </div>

        <div className="justify-content-end">
          <UsefulLinks />
        </div>

        <div className="">
          {" "}
          <ContactShortForm />
        </div>
      </div>
    </ContactWrapper>
  );
};

export default ContactSm;
