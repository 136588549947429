import "../../App.css";
import { motion } from "framer-motion";

const CertificateCard = ({ lnk, image, title, subTitle, detailes }) => {
  return (
    <>
      <div>
        <motion.div
          whileHover={{ scale: [null, 1.25, 1.25] }}
          transition={{ duration: 0.5 }}
        >
          <a href={lnk} target="blank">
            <img src={image} class="img-responsive" alt="serv img" />
          </a>
        </motion.div>
      </div>
      <div className=" text-center">
        <h5 className="certif-h4">{title}</h5>

        <p className="certif-p">{detailes}</p>
        <h7 className="certif-h6">{subTitle}</h7>
      </div>
    </>
  );
};

export default CertificateCard;
