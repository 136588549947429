import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

import ScrollToTop from "./Components/ScrollToTop";

import "./App.css";

import { Header, Footer } from "./sections/index";
import {
  Home,
  AboutPage,
  ServicesPage,
  ProjectsPage,
  CertificatesPage,
  ContactPage,
} from "./Pages/index";
import { Container } from "./Components/index";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            CertificatesPage
          </Routes>
        </ScrollToTop>
        <Footer />
      </Router>
    </>
  );
};

export default App;
