import "../../App.css";
import myImage from "../../assets/images/w-avatar.png";
import { CoverText } from "../../Components/index";
import { mainData } from "../../Data/mainData";

const Main = () => {
  return (
    <div className="main">
      <div className="container text-center">
        <CoverText
          coverTitle={mainData.title}
          coverSubtitle={mainData.subtitle}
        />
      </div>
    </div>
  );
};

export default Main;
