import "../../App.css";
import ProgressBar from "react-bootstrap/ProgressBar";
const htmlPers = 100;
const reactPers = 90;
const nodePers = 85;
const php = 60;
const wordpers = 65;

const WebGraph = () => {
  return (
    <div className="col col-lg-5 col-md-10 col-sm-10 col-xs-10 col-10 justify-content-end align-items-center web-graph">
      <div className="one-graph">
        <div className="graph-title">
          <span>HTML5 CSS3</span>
          <span>{`${htmlPers}%`}</span>
        </div>
        <ProgressBar
          variant="success"
          now={htmlPers}
          label={`${htmlPers}%`}
          visuallyHidden
        />
      </div>

      <div className="one-graph">
        <div className="graph-title">
          <span>JavaScript ReactJs</span>
          <span>{`${reactPers}%`}</span>
        </div>
        <ProgressBar
          variant="danger"
          now={reactPers}
          label={`${reactPers}%`}
          visuallyHidden
        />
      </div>

      <div className="one-graph">
        <div className="graph-title">
          <span>NodeJs</span>
          <span>{`${nodePers}%`}</span>
        </div>
        <ProgressBar
          variant="info"
          now={nodePers}
          label={`${nodePers}%`}
          visuallyHidden
        />
      </div>

      <div className="one-graph">
        <div className="graph-title">
          <span>PHP</span>
          <span>{`${php}%`}</span>
        </div>
        <ProgressBar
          variant="warning"
          now={php}
          label={`${php}%`}
          visuallyHidden
        />
      </div>

      <div className="one-graph">
        <div className="graph-title">
          <span>WordPress</span>
          <span>{`${wordpers}%`}</span>
        </div>
        <ProgressBar
          variant="primary"
          now={wordpers}
          label={`${wordpers}%`}
          visuallyHidden
        />
      </div>
    </div>
  );
};

export default WebGraph;
