import "../../App.css";

const ContactWrapper = (props) => {
  return (
    <div className="contact-wrapper">
      <div className="container">{props.children}</div>
    </div>
  );
};

export default ContactWrapper;
