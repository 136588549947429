import "../../App.css";
import { TechLogos, Intro } from "../../Components/index";
import {
  WebLang,
  CertificateSec,
  Contact,
  ContactSm,
} from "../../sections/index";
import { AboutWall } from "../../Components/index";
import { aboutData } from "../../Data/aboutData";

const AboutPage = () => {
  return (
    <>
      <AboutWall />
      <Intro
        me={aboutData.mainPreName}
        name={aboutData.mainName}
        parahraph={aboutData.mainDetal}
      />
      <TechLogos />
      <WebLang />
      <CertificateSec />
      <div className="contact-footer-lg">
        <Contact />
      </div>
      <div className="contact-footer-sm">
        <ContactSm />
      </div>{" "}
    </>
  );
};

export default AboutPage;
