import "../../App.css";
import { Link } from "react-router-dom";

import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaGithubSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div class="container">
        <div class="d-flex flex-row flex-wrap justify-content-between">
          <div class="d-flex  align-items-center footer-para">
            <p className="all-rights-lg">
              <div>
                {" "}
                Copyright © 2022{" "}
                <a href="https://github.com/wael65/" target="blank">
                  WAEL MGD
                </a>
                <span> All rights reserved.</span>
              </div>
            </p>
          </div>
          <div className="footer-para" style={{ margin: "auto" }}>
            <p className="all-rights-sm">
              <div>
                {" "}
                Copyright © 2022{" "}
                <a href="https://github.com/wael65/" target="blank">
                  WAEL MGD
                </a>
              </div>
              <div> All rights reserved.</div>
            </p>
          </div>

          <div class="d-flex col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 align-items-center justify-content-center social-lnk">
            <a href="https://www.facebook.com/waelabdlmeged" target="blank">
              <FaFacebookSquare className="social-logo" />
            </a>

            <a href="https://twitter.com/CodeLover19" target="blank">
              <FaTwitterSquare className="social-logo" />
            </a>

            <a href="#" target="blank">
              <FaInstagramSquare className="social-logo" />
            </a>

            <a
              href="https://www.linkedin.com/in/wael-abdlmeged/"
              target="blank"
            >
              <FaLinkedin className="social-logo" />
            </a>

            <a href="https://github.com/wael65" target="blank">
              <FaGithubSquare className="social-logo" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
