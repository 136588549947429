import { FaAtom, FaDatabase, FaDesktop } from "react-icons/fa";
import serv_image_1 from "../assets/images/static-website.png";
import serv_image_2 from "../assets/images/dynamic-website.png";
import serv_image_3 from "../assets/images/database-web.png";

const servImg1 = (
  <img src={serv_image_1} class="img-responsive" alt="serv img" />
);
const servImg2 = (
  <img src={serv_image_2} class="img-responsive" alt="serv img" />
);
const servImg3 = (
  <img src={serv_image_3} class="img-responsive" alt="serv img" />
);

export const servicesData = [
  {
    id: "serv-1",
    name: "Website",
    avatar: <FaDesktop />,
    image: servImg1,
    backGround: "#c14000",
    shoreDetal:
      "I have expertise in building and editing websites using WordPress and various pre-built themes. I can create different categories ",
    detal:
      "I have expertise in building and editing websites using WordPress and various pre-built themes. I can create different categories of websites, including blogs, e-commerce platforms, portfolios, forums, and e-learning platforms. The websites I build are responsive, ensuring seamless user experience across devices and mobile phones. Moreover, they are highly secure and cost-competitive.",
  },
  {
    id: "serv-2",
    name: "Dynamic Website",
    avatar: <FaAtom />,
    image: servImg2,
    backGround: "#009988",
    shoreDetal:
      "I also create and edit dynamic websites. I utilize React, Redux, HTML, CSS, JavaScript, Bootstrap, and MaterialUI to design ",
    detal:
      "I also create and edit dynamic websites. I utilize React, Redux, HTML, CSS, JavaScript, and MaterialUI to design the website, and then leverage JavaScript and React to build the front-end. The result is a website that perfectly aligns with your business requirements, targets the right market segment, and meets all your needs.",
  },
  {
    id: "serv-3",
    name: "Database and API",
    avatar: <FaDatabase />,
    image: servImg3,
    backGround: "#ffa500",
    shoreDetal:
      "In many cases, we require a tailored back-end is necessary for web applications. I have the skills to build APIs and  ",
    detal:
      "In many cases, we require a tailored back-end is necessary for web applications. I have the skills to build APIs and databases to support your web application. I primarily work with Node.js alongside databases such as PostgreSQL, MySQL, and MongoDB. These projects require all front-end and back-end expertise to deliver a comprehensive website solution.",
  },
];

export const servicesMainData = {
  mainTitle: "Services",
  paragraph:
    "I offer comprehensive services to elevate your business to new heights. I specialize in developing, designing, and building unique websites and applications tailored to your needs.",
};
