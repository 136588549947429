import "../../App.css";

import {
  Main,
  About,
  Service,
  Project,
  CertificateSec,
  Contact,
  ContactSm,
} from "../../sections/index";

const Home = () => {
  return (
    <>
      <Main />
      <Service />
      <About />
      <Project />
      <CertificateSec />
      <div className="contact-footer-lg">
        <Contact />
      </div>
      <div className="contact-footer-sm">
        <ContactSm />
      </div>
    </>
  );
};

export default Home;
