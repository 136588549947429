import "../../App.css";
import { CoverTextRight } from "../index";

const ServicesWall = () => {
  return (
    <div className="services-header">
      <div className="container">
        <CoverTextRight title="All Services" subTitle="You Are Lokking For" />
      </div>
    </div>
  );
};

export default ServicesWall;
