import "../../App.css";
import { contctAllData } from "../../Data/contactData";

const ContactCard = () => {
  return (
    <div style={{ fontSize: "1em" }}>
      <img src={contctAllData.logo} class="img-responsive" alt="about img" />
      <p style={{ textAlign: "justify" }}>{contctAllData.cardIntro}</p>
      <p>
        {contctAllData.phone.avatar}
        {contctAllData.phone.descreption}
      </p>
      <p>
        {contctAllData.mail.avatar}
        {contctAllData.mail.descreption}
      </p>
      <p>
        {contctAllData.website.avatar}
        {contctAllData.website.descreption}
      </p>
    </div>
  );
};

export default ContactCard;
