import "../../App.css";
import {
  PrimaryButton,
  IntroSummary,
  PortfolioCard,
} from "../../Components/index";

import { projectHomeData } from "../../Data/projectsData";

const Project = () => {
  return (
    <div className="container text-center margin-sec">
      <div className=" ">
        <IntroSummary
          title={projectHomeData.title}
          para={projectHomeData.para}
        />
      </div>
      <div>
        <PortfolioCard />
      </div>
      <div style={{ marginTop: "0", marginBottom: "5em" }}>
        <PrimaryButton href="/projects" content="View More" />
      </div>
    </div>
  );
};

export default Project;
