import "../../App.css";
import { SreviceSec, Contact, ContactSm } from "../../sections/index";
import { ServicesWall } from "../../Components/index";

const ServicesPage = () => {
  return (
    <div>
      <ServicesWall />
      <SreviceSec />
      <div className="contact-footer-lg">
        <Contact />
      </div>
      <div className="contact-footer-sm">
        <ContactSm />
      </div>{" "}
    </div>
  );
};

export default ServicesPage;
