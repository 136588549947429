import { Link } from "react-router-dom";
import "../../App.css";
import logo from "../../assets/images/logo-onetel.png";
import NavItem, { NavItemDropDown } from "../../Components/NavItem/NavItem";

const Header = () => {
  return (
    <div className="container">
      <div className="navbar navbar-default navbar-expand-md navbar-dark text-white">
        <div class="navbar-header">
          <Link to="/home" className="navbar-brand">
            <img src={logo} alt="" />
          </Link>
        </div>

        <div className="collapse navbar-collapse" id="mainmenu">
          <ul className="navbar-nav ms-auto">
            <NavItem className="nav-item">
              <Link to="/about" className="nav-link">
                About
              </Link>
            </NavItem>

            <NavItem className="nav-item">
              <Link to="/services" className="nav-link">
                Services
              </Link>
            </NavItem>

            <NavItem className="nav-item">
              <Link to="/projects" className="nav-link">
                Projects
              </Link>
            </NavItem>

            {/* <NavItem className="nav-item">                                
                                <Link to="/certificates" className="nav-link">Certificates</Link>
                            </NavItem> */}

            <NavItem className="nav-item">
              <Link to="/contact" className="nav-link">
                Contacts
              </Link>
            </NavItem>
          </ul>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mainmenu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
  );
};

export default Header;
