import "../../App.css";
import { SectionWrapper } from "../../Components/index";

const ServiceCard = ({ img, titl, para, id }) => {
  return (
    <div style={{ marginBottom: "3em" }}>
      <SectionWrapper>
        <div className="d-flex flex-wrap justify-content-around  ">
          {id === "serv-2" ? (
            <>
              <div class="col col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12 serv-des-rev">
                <h2>{titl}</h2>
                <p>{para}</p>
              </div>
              <div class="col col-lg-4 col-md-4 col-sm-11 col-xs-11 col-11  serv-image">
                <div
                // class="serv-image-wrapp"
                >
                  {img}
                </div>
              </div>
            </>
          ) : (
            <>
              <div class="col col-lg-4 col-md-4 col-sm-11 col-xs-11 col-11  serv-image">
                <div class="serv-image-wrapp">{img}</div>
              </div>
              <div class="col col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12  serv-des">
                <h2>{titl}</h2>
                <p>{para}</p>
              </div>
            </>
          )}
        </div>
      </SectionWrapper>
    </div>
  );
};

export default ServiceCard;
