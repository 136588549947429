import "../../App.css";
import { LangDetailes, WebGraph } from "../../Components/index";
import { aboutData } from "../../Data/aboutData";

const WebLang = () => {
  return (
    <div
      className="container "
      style={{ marginTop: "5em", marginBottom: "5em" }}
    >
      <hr />
      <div className="d-flex flex-row flex-wrap justify-content-center web-lang ">
        <LangDetailes
          title={aboutData.secondTitle}
          para={aboutData.SecondDetal}
        />
        <WebGraph />
      </div>
    </div>
  );
};

export default WebLang;
