import { Link } from "react-router-dom";
import "../../App.css";

const PrimaryButton = (props) => {
  return (
    <>
      <div class="col-md-12 col-sm-12 ">
        <Link to={props.href} className="btn btn-default main-button">
          {props.content}
        </Link>
      </div>
    </>
  );
};

const SecondaryButton = (props) => {
  return (
    <>
      <div class="main-border-button">
        <Link to={props.href - 2}>{props.children}</Link>
      </div>
    </>
  );
};

const SeeMore = ({ href_3 }) => {
  return (
    <>
      <div class="see-more-button">
        <Link to={href_3}>
          {" "}
          <span> ...See More</span>
        </Link>
      </div>
    </>
  );
};

export default PrimaryButton;
export { SecondaryButton };
export { SeeMore };
