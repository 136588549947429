import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../../App.css";
import { contctAllData } from "../../Data/contactData";
import { ToastContainer } from "react-toastify";
import notify from "../useNotify";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ieh4wdi",
        "template_n9a1fkp",
        form.current,
        "R2UYDjTcfJ10GWcyg"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    notify("Your Message Sent Successfully", "success");
    e.target.reset();
  };

  return (
    <div className="container">
      <div className=" mail-form form-lg">
        <h5
          style={{
            width: "40%",
            textAlign: "center",
          }}
        >
          Message Me
        </h5>
        <div className="d-flex flex-row ">
          <form
            className="d-flex flex-row justify-content-center input-group "
            ref={form}
            onSubmit={sendEmail}
          >
            <div
              className=" mb-3"
              style={{
                width: "40%",
                paddingRight: "0.5em",
              }}
            >
              <input
                type="text"
                name="user_name"
                className="form-control form-control-lg  mb-3"
                placeholder="Type Your Name"
                style={{ fontSize: "0.75em" }}
              />
              <input
                type="email"
                name="user_email"
                className=" form-control form-control-lg "
                placeholder="Type Your Email"
                style={{ fontSize: "0.75em" }}
              />
            </div>
            <div
              className="mb-3 d-flex flex-column justify-items-end"
              style={{
                width: "40%",
                paddingLeft: "0.75em",
              }}
            >
              <textarea
                name="message"
                className="form-control form-control-sm"
                placeholder="Type The Message"
                style={{ fontSize: "0.75em", borderRadius: "0.75em" }}
              />
              <input
                className="btn-email"
                type="submit"
                value="Send"
                style={{
                  width: "30%",
                  alignSelf: "end",
                }}
              />
            </div>
          </form>
        </div>
      </div>

      <div className=" mail-form form-sm">
        <h5
          style={{
            textAlign: "center",
          }}
        >
          Message Me
        </h5>
        <div className="d-flex flex-row ">
          <form
            className="d-flex flex-row justify-content-center input-group "
            ref={form}
            onSubmit={sendEmail}
          >
            <div
              className=" mb-3"
              style={{
                width: "80%",
                paddingRight: "0.5em",
              }}
            >
              <input
                type="text"
                name="user_name"
                className="form-control form-control-lg mb-2"
                placeholder="Type Your Name"
                style={{ fontSize: "0.75em" }}
              />
              <input
                type="email"
                name="user_email"
                className=" form-control form-control-lg mb-2"
                placeholder="Type Your Email"
                style={{ fontSize: "0.75em" }}
              />
              <textarea
                name="message"
                className="form-control form-control-sm"
                placeholder="Type The Message"
                style={{ fontSize: "0.75em", borderRadius: "0.75em" }}
              />
              <input
                className="btn-email"
                type="submit"
                value="Send"
                style={{
                  width: "30%",
                  alignSelf: "end",
                }}
              />
            </div>
          </form>
        </div>
      </div>

      <div className=" mail-form form-lg">
        <h5 style={{ width: "40%", textAlign: "center" }}>Contact Me</h5>
        <div
          className="d-flex fles-row justify-content-around"
          style={{ width: "100%" }}
        >
          <h7>
            <span
              style={{
                fontSize: "1em",
                fontWeight: "600",
                color: "#fff",
              }}
            >
              {contctAllData.adress.title}
            </span>{" "}
            <p
              className=""
              style={{
                fontSize: "1em",
                fontWeight: "normal",
                marginBottom: "0",
              }}
            >
              {contctAllData.adress.descreption}
            </p>
          </h7>
          <h7>
            <span
              style={{
                fontSize: "1em",
                fontWeight: "600",
                color: "#fff",
              }}
            >
              {contctAllData.mail.title}
            </span>{" "}
            <p
              className=""
              style={{
                fontSize: "1em",
                fontWeight: "normal",
                marginBottom: "0",
              }}
            >
              {contctAllData.mail.descreption}
            </p>
          </h7>
          <h7>
            <span
              style={{
                fontSize: "1em",
                fontWeight: "600",
                color: "#fff",
              }}
            >
              {contctAllData.phone.title}
            </span>{" "}
            <p className="" style={{ fontSize: "1em", fontWeight: "normal" }}>
              {contctAllData.phone.descreption}
            </p>
          </h7>
        </div>
      </div>

      <div className=" mail-form form-sm">
        <h5 style={{ textAlign: "center" }}>Contact Me</h5>
        <div className="d-flex flex-wrap flex-row justify-content-around ">
          <h7 style={{ width: "100%", paddingTop: "0.3em" }}>
            <span
              style={{
                fontSize: "1em",
                fontWeight: "600",
                color: "#fff",
              }}
            >
              {contctAllData.adress.title}
            </span>{" "}
            <p
              className=""
              style={{
                fontSize: "1em",
                fontWeight: "normal",
                marginBottom: "0",
              }}
            >
              {contctAllData.adress.descreption}
            </p>
          </h7>
          <h7 style={{ width: "100%", paddingTop: "0.3em" }}>
            <span
              style={{
                fontSize: "1em",
                fontWeight: "600",
                color: "#fff",
              }}
            >
              {contctAllData.mail.title}
            </span>{" "}
            <p
              className=""
              style={{
                fontSize: "1em",
                fontWeight: "normal",
                marginBottom: "0",
              }}
            >
              {contctAllData.mail.descreption}
            </p>
          </h7>
          <h7 style={{ width: "100%", paddingTop: "0.3em" }}>
            <span
              style={{
                fontSize: "1em",
                fontWeight: "600",
                color: "#fff",
              }}
            >
              {contctAllData.phone.title}
            </span>{" "}
            <p className="" style={{ fontSize: "1em", fontWeight: "normal" }}>
              {contctAllData.phone.descreption}
            </p>
          </h7>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
