/* eslint-disable jsx-a11y/iframe-has-title */
import "../../App.css";
import { IntroSummary } from "../index";

const Map = () => {
  return (
    <>
      <div className="container map-box ">
        <div className="map-title-lg">
          <h5 style={{ width: "40%", textAlign: "center" }}>Location</h5>
        </div>
        <div className="map-title-sm">
          <h5>Location</h5>
        </div>
        <div className=" map-container">
          <div className="d-flex col-12 col-md-12 justify-content-center map-wrapper">
            <iframe
              calssName="map-el"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d213.2317296096588!2d29.950982965029134!3d31.228827338494867!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5c4c2a769378b%3A0x50cf3ed32436eafb!2s9%20Syria%2C%20st%2C%20El%20Raml%201%2C%20Alexandria%20Governorate%2021529!5e0!3m2!1sen!2seg!4v1672829448276!5m2!1sen!2seg"
              width="100%"
              height="100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Map;
