import "../../App.css";

import { CoverTextRiRe } from "../index";

const ProjectWall = () => {
  return (
    <div className="project-header">
      <div className="container">
        <CoverTextRiRe title="GO ALIVE" subTitle="We Make Your Needs " />
      </div>
    </div>
  );
};

export default ProjectWall;
