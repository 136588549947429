import "../../App.css";
import { IntroSummary, ContactForm } from "../../Components/index";
import { contctAllData } from "../../Data/contactData";

const ContactSec = () => {
  return (
    <div className="container">
      <div className="text-center proj-main pt-4">
        <IntroSummary
          title={contctAllData.pageTitle}
          para={contctAllData.PageIntro}
        />
      </div>

      <ContactForm />
    </div>
  );
};

export default ContactSec;
