import "../../App.css";

import {
  CertificateCard,
  IntroSummary,
  SectionWrapper,
} from "../../Components/index";
import { certificateMainData, certificatesData } from "../../Data/aboutData";

const CertificateSec = () => {
  return (
    <div className="container margin-sec">
      <SectionWrapper style={{ padding: "0em" }}>
        <div className=" d-flex flex-row flex-wrap justify-content-center ">
          <div className="text-center mb-3">
            <IntroSummary
              title={certificateMainData.title}
              para={certificateMainData.detal}
            />
          </div>
          <div className=" d-flex flex-wrap justify-content-center mb-5 certif-group">
            <div className=" d-flex flex-wrap justify-content-center ">
              {certificatesData.map((item) => {
                return (
                  <div
                    className="d-flex flex-wrap  justify-content-center certif-card"
                    style={{
                      marginRight: "0.25em",
                      marginLeft: ".25em",
                      marginBottom: "2em",
                    }}
                  >
                    <CertificateCard
                      lnk={item.link}
                      image={item.image}
                      title={item.title}
                      subTitle={item.from}
                      detailes={item.detal}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export default CertificateSec;
