import "../../App.css";
import myImage from "../../assets/images/w-avatar.png";

const CoverText = ({ coverTitle, coverSubtitle }) => {
  return (
    <div className="">
      <div className="container text-center">
        <div class="d-flex flex-row ">
          <div class="col-12 about-cover-txt cover-txt-lg">
            <br />
            <h1>{coverTitle}</h1>
            <br />
            <h2>{coverSubtitle}</h2>
          </div>
          <div class="col-12 cover-txt-sm">
            <br />
            <h1>{coverTitle}</h1>
            <br />
            <h4 style={{ textShadow: "2.5px 2.5px #333" }}>{coverSubtitle}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const CoverTextRight = (props) => {
  return (
    <div className="service-cover-txt">
      <div className="container">
        <div class="d-flex flex-row-reverse ">
          <div class="col col-sm-12 col-md-12 col-lg-6 col-xs-12">
            <h1>{props.title}</h1>
            <h3>{props.subTitle}</h3>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

const CoverTextRiRe = (props) => {
  return (
    <div className="reri-cover-txt">
      <div className="container">
        <div class="d-flex flex-row-reverse ">
          <div class="col col-sm-12 col-md-12 col-lg-6 col-xs-12">
            <h3>{props.subTitle}</h3>
            {/* <br /> */}
            <h1>{props.title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

const CoverTextAbout = ({ coverTitle, coverSubtitle }) => {
  return (
    <div className="about-cover-txt">
      <div className="container">
        <div
          class="d-flex flex-wrap justify-content-center"
          style={{ margin: "auto" }}
        >
          <div
            class="d-flex flex-column flex-wrap justify-content-center"
            style={{ margin: "auto" }}
          >
            <h1 style={{ margin: "auto" }}>{coverTitle}</h1>
            <br />
            <h4 style={{ margin: "auto" }}>{coverSubtitle}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const CoverTextLeft = (props) => {
  return (
    <div className="service-cover-txt">
      <div className="container">
        <div class="d-flex flex-row">
          <div class="col col-sm-12 col-md-12 col-lg-6 col-xs-12">
            <h1>{props.tit}</h1>
            <h3>{props.subTit}</h3>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverText;
export { CoverTextRight };
export { CoverTextRiRe };
export { CoverTextLeft };
export { CoverTextAbout };
