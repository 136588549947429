import "../../App.css";
import { motion } from "framer-motion";

import { projectsDetails } from "../../Data/projectsData";

const PortfolioCard = () => {
  return (
    <div className="container">
      <div className="d-flex flex-wrap justify-content-center">
        <div
          className="d-flex flex-wrap justify-content-center  "
          style={{ marginBottom: "0.75em" }}
        >
          <div
            className="d-flex flex-wrap justify-content-center  "
            style={{ backgroundColor: "#101010", margin: "auto" }}
          >
            <iframe
              width="85%"
              src="https://www.youtube.com/embed/3vVwtu6rfjU"
              title="Online-Store App Promo"
              frameborder="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class=" d-flex flex-row flex-wrap justify-content-center proj-sec ">
          {projectsDetails.map((project) => {
            return (
              <div
                key={project.id}
                className="d-flex justify-content-center proj-card"
                style={{
                  marginRight: "0.15em",
                  marginLeft: "0.15em",
                }}
              >
                <motion.div
                  whileHover={{ scale: [null, 1.25, 1.25] }}
                  transition={{ duration: 0.7 }}
                  className="motion-div-lg"
                  style={{ width: 300, hight: 150, margin: "0.5em" }}
                >
                  <a href="/projects" data-lightbox-gallery="portfolio-gallery">
                    <img
                      src={project.image}
                      class="img-responsive"
                      alt="portfolio img"
                    />
                  </a>
                </motion.div>

                <motion.div
                  whileHover={{ scale: [null, 1.25, 1.25] }}
                  transition={{ duration: 0.7 }}
                  className="motion-div-sm"
                  style={{ width: 300, hight: 150, margin: "0.5em" }}
                >
                  <a href="/projects" data-lightbox-gallery="portfolio-gallery">
                    <img
                      src={project.image}
                      class="img-responsive"
                      alt="portfolio img"
                    />
                  </a>
                </motion.div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
