import "../../App.css";
import { Divider } from "../../Components/index";
import { FaAtom, FaDatabase, FaDesktop } from "react-icons/fa";
import { SeeMore } from "../../Components/index";
import { servicesData } from "../../Data/servicesData";

const Service = () => {
  return (
    <>
      <div className="divider">
        <div className="container">
          <div class=" d-flex flex-row flex-wrap justify-content-center ">
            {servicesData.map((service) => {
              return (
                <div
                  className="divider-wrapper "
                  style={{ backgroundColor: service.backGround }}
                >
                  <Divider
                    image={service.avatar}
                    title={service.name}
                    detailes={service.shoreDetal}
                    seemore={<SeeMore href_3="./services" />}
                    background={service.backGround}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
