import port_image_1 from "../assets/images/project-store-img33.png";
import port_image_2 from "../assets/images/project-weather-img2.png";
import port_image_3 from "../assets/images/project-gomastery-img3.png";
import port_image_4 from "../assets/images/project-myreader-img2.png";
import port_image_5 from "../assets/images/project-salary-img.png";
import port_image_6 from "../assets/images/project-idinfo-img.png";
import port_image_7 from "../assets/images/project-tatwer-img-3.png";

export const mainProjectDetails = {
  id: "project_Main",
  image: port_image_1,
  title: "Online Store",
  sub_title: "React JS, Redux, Node JS ,and MongoDB",
  detail:
    "Online store created by Node Js as a Back-end, MongoDB as a Database, and React Js with Redux as a front-end",
};

export const projectsDetails = [
  {
    id: "project_2",
    image: port_image_2,
    title: "weather App",
    sub_title: "React JS",
    link: "https://main--react-weather24.netlify.app/",
    detail:
      "An asynchronous app that uses API, to get the weather information website, and dynamically updates the UI.",
  },
  {
    id: "project_4",
    image: port_image_4,
    title: "My Reader App",
    sub_title: "React JS",
    detail:
      "A front end for an App created using ReactJs; that searches for books in the database and organizes them on specific shelves.",
  },
  {
    id: "project_3",
    image: port_image_3,
    title: "Company website",
    sub_title: "WordPress",
    link: "https://go-mastery.com/",
    detail:
      "A website for a company that provides services and this website is made by WordPress and MySql database.",
  },
  {
    id: "project_7",
    image: port_image_7,
    title: "Arabic Website-",
    sub_title: "WordPress",
    detail:
      "An Arabic website for a company that provides all company Information and services. It is another WordPress website.",
  },
  {
    id: "project_5",
    image: port_image_5,
    title: "Salary App",
    sub_title: "PHP - MySQL",
    detail:
      "An App was created using PHP and MySQL databases to calculate salaries and provide all reports needed.",
  },
  {
    id: "project_6",
    image: port_image_6,
    title: "Id Info App",
    sub_title: "PHP",
    detail:
      "An App was created using PHP; it gets the full name; and ID number from the user's input, then extracts personal info",
  },
];

export const projectSectionDetails = {
  mainTitle: "Projects",
  paragraph:
    "I built many websites during my work journey. I made these websites using different languages and platforms. Here are some of these websites",
};

export const projectHomeData = {
  title: "Recent Projects",
  para: "This is a chosen gallery of my projects. I've done them all together with smart people  I've done them all together with amazing people from companies around the globe. It's only a drop in the ocean compared to the entire list. I made these websites using different languages and platforms.",
};
