import "../../App.css";
import { CoverTextAbout } from "../index";
import { aboutCoverData } from "../../Data/aboutData";

const AboutWall = () => {
  return (
    <div className="about-header">
      <div className="container">
        <CoverTextAbout
          coverTitle={aboutCoverData.title}
          coverSubtitle={aboutCoverData.subtitle}
        />
      </div>
    </div>
  );
};

export default AboutWall;
