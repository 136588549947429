import "../../App.css";

const ProjectCard = ({ image, title, subTitle, detailes, link, seemore }) => {
  return (
    <div style={{ minHeight: "25em" }}>
      <a href={link} target="blank">
        <img
          src={image}
          className="img-fluid proj-page-img"
          alt="project-img"
        />
      </a>
      <h4 className="proj-h4">{title}</h4>
      <h6 className="proj-h6">{subTitle}</h6>
      <p className="proj-p">
        {detailes} <span>{seemore}</span>
      </p>
    </div>
  );
};

export default ProjectCard;
