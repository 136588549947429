import "../../App.css";

import { PrimaryButton } from "../../Components/index";
import { aboutData } from "../../Data/aboutData";

const AboutCard = () => {
  return (
    <div className="d-flex flex-row flex-wrap justify-content-around about-image">
      <div class="col-11 col-md-6 col-sm-12 ">
        <img src={aboutData.image} class="img-responsive" alt="about img" />
      </div>
      <div class="col-11 col-md-6 col-sm-12 about-des">
        <h2>{aboutData.homeTitle}</h2>
        <p>{aboutData.homeDetal}</p>
        <div className="about-btn">
          <PrimaryButton href="./about" content="Learn more" />
        </div>
      </div>
    </div>
  );
};

export default AboutCard;
