import { Link } from "react-router-dom";
import { contctAllData } from "../../Data/contactData";

const UsefulLinks = () => {
  return (
    <>
      <h4>Links</h4>
      {contctAllData.links.map((link) => {
        return (
          <p key={link}>
            <Link to={`/${link.toLowerCase()}`} className="nav-link">
              {link}
            </Link>
          </p>
        );
      })}
    </>
  );
};

export default UsefulLinks;
