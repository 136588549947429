import "../../App.css";
import {
  ServiceCard,
  ServiceCardRev,
  IntroSummary,
  SectionWrapper,
} from "../../Components/index";

import { servicesMainData, servicesData } from "../../Data/servicesData";

const SreviceSec = () => {
  return (
    <div className="container text-center ">
      <div className="text-center my-5 pt-3">
        <IntroSummary
          title={servicesMainData.mainTitle}
          para={servicesMainData.paragraph}
        />
      </div>

      <div className="container text-center serv-main-cont ">
        {servicesData.map((service) => {
          return (
            <div className="serv-cont" key={service.id}>
              <ServiceCard
                img={service.image}
                titl={service.name}
                para={service.detal}
                id={service.id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SreviceSec;
