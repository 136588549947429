import { FaPhoneAlt, FaGlobeAmericas, FaEnvelope } from "react-icons/fa";
import logo from "../assets/images/logo-onetel.png";

export const contctAllData = {
  logo: logo,
  pageTitle: "Keep In Touch",
  coverTxt: "Fell Free To Contact Me",
  cardIntro:
    "Should you wish to create a website or App, contact me and let me know more about your request.",
  PageIntro:
    "I build custom websites and applications, fix, maintain, and upgrade the websites using famous and latest technologies",
  adress: {
    title: "Adress :",
    descreption: "Roshdy, Alex, Egypt",
  },
  phone: {
    avatar: <FaPhoneAlt />,
    title: "Phone : ",
    descreption: "  015-0822-6504",
  },
  mail: {
    avatar: <FaEnvelope />,
    title: "E mail :",
    descreption: " contact@waelmged.com",
  },
  website: {
    avatar: <FaGlobeAmericas />,
    title: "",
    descreption: "  www.waelmged.com",
  },

  links: ["Home", "About", "Services", "Projects", "Contact"],

  newsletter:
    "Please provide your email address so that I can send you updates on websites and special offers.",
};
