import "../../App.css";
// import {
//   FaHtml5,
//   FaCss3Alt,
//   FaWordpress,
//   FaNode,
//   FaBootstrap,
//   FaReact,
// } from "react-icons/fa";

// import {
//   SiJavascript,
//   SiTypescript,
//   SiPhp,
//   SiMysql,
//   SiPostgresql,
//   SiMongodb,
// } from "react-icons/si";

import { techLogoData } from "../../Data/aboutData";

import ContactWrapper from "../ContactWrapper/ContactWrapper";

const TechLogos = () => {
  return (
    <div className="container ">
      <div
        className=""
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "5em",
          paddingBottom: "0em",
        }}
      >
        <h2>Technologies</h2>
      </div>
      <div className="container t-logo-lg">
        <div
          class="d-flex flex-row flex-wrap justify-content-center group-logo"
          style={{ width: "70%", margin: "auto" }}
        >
          {techLogoData.map((logo) => {
            return (
              <div
                key={logo.id}
                className=" d-flex flex-row flex-wrap tech-logos "
                style={{ margin: "0.75em" }}
              >
                <div className=" wrapp-logos ">{logo}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="container t-logo-sm">
        <div
          class="d-flex flex-row flex-wrap justify-content-center group-logo"
          // style={{ width: "70%", margin: "auto" }}
        >
          {techLogoData.map((logo) => {
            return (
              <div
                key={logo.id}
                className=" d-flex flex-row flex-wrap tech-logos "
                style={{ margin: "0.75em" }}
              >
                <div className=" wrapp-logos ">{logo}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TechLogos;
