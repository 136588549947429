import "../../App.css";

import { ProjectSec, Contact, ContactSm } from "../../sections/index";
import { ProjectWall } from "../../Components/index";

const ProjectsPage = () => {
  return (
    <>
      <ProjectWall />
      <ProjectSec />
      <div className="contact-footer-lg">
        <Contact />
      </div>
      <div className="contact-footer-sm">
        <ContactSm />
      </div>{" "}
    </>
  );
};

export default ProjectsPage;
