import about_image from "../assets/images/about-img2.jpg";
import about_image_2 from "../assets/images/italy-profile-3.jpg";
import about_image_3 from "../assets/images/ger-profile-1.jpg";
import about_image_4 from "../assets/images/italy-profile-1.jpg";
import about_image_5 from "../assets/images/holand-profile-1.jpg";
import certificate_1 from "../assets/images/React Certificate.jpg";
import certificate_2 from "../assets/images/Professional Certificate.png";
import certificate_3 from "../assets/images/Advanced Certtificate.png";
import {
  FaHtml5,
  FaCss3Alt,
  FaWordpress,
  FaNode,
  FaBootstrap,
  FaReact,
} from "react-icons/fa";

import {
  SiJavascript,
  SiTypescript,
  SiPhp,
  SiMysql,
  SiPostgresql,
  SiMongodb,
} from "react-icons/si";

export const aboutData = {
  homeTitle: "About Me",
  homeDetal:
    "Hello, my name is WAEL ABDELMEGED a React web developer passionate about building apps and websites and implementing projects using  React JS, Redux, HTML, CSS, JavaScript, and Bootstrap..",
  image: about_image_2,
  mainPreName: "I'am",
  mainName: "WAEL ABDELMEGED",
  mainDetal:
    "Hello, my name is WAEL ABDELMEGED a React web developer passionate about building apps and websites and implementing projects using  React JS, HTML, CSS, JavaScript, and Bootstrap.I also deal with the back-end using TypeScript, and Node Js, besides PostgreSQL, MySQL, and MongoDB databases. In addition, I use WordPress to create amazing websites. I handle many other web technologies like PHP, AWS services, and Python. Throughout my workday, I review codes, fix bugs, and improve the code. Generally, I have additional skills necessary to accomplish my work such as Agial fundamentals, Marketing principles, and digital marketing skills. I'm always trying to get better so I can fulfill my desire.",
  secondTitle: "Technologies Used",
  SecondDetal:
    "I create websites using variant technologies. Based on the features needed for each website or App, I choose the suitable technologies to build it. Each language and framework has different tools and could be perfect in each case based on the customer's needs.",
};

export const aboutCoverData = {
  title: "Code",
  subtitle: "To Make The World Better!",
};

export const certificateMainData = {
  title: "Certificates",
  detal:
    "I continually improve my talents to achieve the best performance and always learn the newest knowledge. Here are my latest certificates from Udacity, which I obtained while acquiring new knowledge..",
};

export const certificatesData = [
  {
    image: certificate_1,
    title: "React JS",
    detal: "Front-End React Development Cross-Skilling",
    from: "From Udacity",
    link: "https://confirm.udacity.com/LDNHFUDT",
  },
  {
    image: certificate_2,
    title: "Front End",
    detal: "Front-End Web Development Professional",
    from: "From Udacity",
    link: "https://confirm.udacity.com/SPAEDCEP",
  },
  {
    image: certificate_3,
    title: "Full-Stack",
    detal: "Full-Stack Web Development Advanced",
    from: "From Udacity",
    link: "https://confirm.udacity.com/NCQYWGPA",
  },
];

export const techLogoData = [
  <FaHtml5 className="logos" />,
  <FaCss3Alt className="logos" />,
  <SiJavascript className="logos" />,
  <FaReact className="logos" />,
  <FaNode className="logos" />,
  <SiTypescript className="logos" />,
  <SiPhp className="logos" />,
  <FaWordpress className="logos" />,
  <FaBootstrap className="logos" />,
  <SiMongodb className="logos" />,
  <SiPostgresql className="logos" />,
  <SiMysql className="logos" />,
];
