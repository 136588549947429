import "../../App.css";
import { motion, useTime, useTransform } from "framer-motion";

const Divider = ({ image, title, detailes, seemore }) => {
  const time = useTime();
  const rotate = useTransform(time, [0, 4000], [0, 360], { clamp: false });

  return (
    <div className="d-flex justify-content-center divider-card">
      <div className="d-flex align-items-center">
        <motion.div style={{ rotate }}>
          <li>{image}</li>
        </motion.div>
      </div>
      <div className="mt-3">
        <h5 style={{ fontWeight: "bold" }}>{title}</h5>
        <p className="devider-detl">
          {detailes} <span>{seemore}</span>
        </p>
      </div>
    </div>
  );
};

export default Divider;
